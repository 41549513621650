import { Table, Tag, Typography } from 'antd'
import { BookingApplication, BookingApplicationStatus } from 'domain/booking'
import moment from 'moment'

import { statusInfoRecord } from '../common'
import { tableFilters } from './tableFilters'
import { useBookingApplicationViewModel } from '../../../view-models'

const { Paragraph } = Typography
const shortDateFormat = 'DD.MM.YYYY'

export const ApplicationsTable = () => {
  const {
    applications,
    totalPages,
    pagination,
    currentPage,
    actions: { dispatch, getBookingApplications },
  } = useBookingApplicationViewModel()

  const setSelectedApplication = (selectedApplication: BookingApplication) => {
    dispatch({ type: 'SET_SELECTED_APPLICATION', payload: { selectedApplication } })
  }

  return (
    <Table
      className='booking-applications-list'
      rowClassName='booking-applications-list-row'
      bordered={true}
      dataSource={applications}
      rowKey={record => record.id}
      pagination={{
        size: 'small',
        total: totalPages * 15,
        pageSize: 15,
        current: currentPage,
        showSizeChanger: false,
      }}
      onChange={(pgnt, filters, sorter) => {
        const statuses: Array<BookingApplicationStatus> | null = filters.title as Array<BookingApplicationStatus> | null

        const { order } = sorter as { order: string | undefined }

        getBookingApplications({
          ...pagination,
          page: pgnt.current,
          sortOrder: order === undefined ? 'DEFAULT' : order === 'ascend' ? 'ASC' : 'DESC',
          statuses: statuses || [],
        })
      }}
    >
      <Table.Column<BookingApplication>
        dataIndex='title'
        sorter={true}
        filters={tableFilters}
        render={(_text, record) => {
          return (
            <div
              data-cy='card-item'
              key={record.id}
              className='booking-applications-list-row-content'
              onClick={() => setSelectedApplication(record)}
            >
              <Paragraph
                ellipsis={{
                  rows: 1,
                }}
                className='title'
              >
                {record.title}
              </Paragraph>
              <div className='info-container'>
                <span className='date'>{moment(record.date).format(shortDateFormat)}</span>
                <Tag
                  id={record.id}
                  color={statusInfoRecord[record.status].color}
                >
                  {statusInfoRecord[record.status].name}
                </Tag>
              </div>
            </div>
          )
        }}
      />
    </Table>
  )
}
