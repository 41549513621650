import {
  BookingAudienceGroup,
  BookingFormFieldBody,
  BookingAudienceGroupBody,
  BookingRepository,
  BookingApplication,
  BookingRole,
  BookingRoleBody,
  BookingApplicationStatus,
} from 'domain/booking'
import { UserProfile } from 'domain/common'
import { EmptyResponse } from '../EmptyResponse'
import { HTTPMethod } from '../HTTPMethod'
import { NetworkService } from '../NetworkService'
import { CacheRequestConfig } from 'axios-cache-interceptor'
import { Pagination } from 'shared/lib/utils/Pagination'

const getGroupsDeleteCacheConfig: Partial<CacheRequestConfig> = {
  cache: {
    cacheTakeover: false,
    update: {
      'get-groups': 'delete',
    },
  },
}

const getRolesDeleteCacheConfig: Partial<CacheRequestConfig> = {
  cache: {
    cacheTakeover: false,
    update: {
      'get-roles': 'delete',
    },
  },
}

export class BookingService extends NetworkService implements BookingRepository {
  getBookingApplications({
    page = 1,
    limit = 15,
    sortOrder = 'DEFAULT',
    title,
    statuses,
  }: {
    page?: number
    limit?: number
    sortOrder?: 'DEFAULT' | 'ASC' | 'DESC'
    title?: string
    statuses?: Array<BookingApplicationStatus>
  }): Promise<Pagination<BookingApplication[]>> {
    const stts = statuses
      ? statuses
          ?.map(status => {
            return `&statuses=${status}`
          })
          .join('')
      : ''

    return this.request(
      `/bookings?page=${page}&limit=${limit}&sortOrder=${sortOrder}${stts ? `${stts}` : ''}${
        title ? `&title=${title}` : ''
      }`,
      HTTPMethod.get,
      null,
      { cache: false }
    )
  }

  approveBooking(bookingID: string): Promise<EmptyResponse> {
    return this.request(`/bookings/${bookingID}/approve`, HTTPMethod.post, null)
  }

  rejectBooking(bookingID: string, reason: string): Promise<EmptyResponse> {
    return this.request(`/bookings/${bookingID}/reject`, HTTPMethod.post, {
      comment: reason,
    })
  }

  getBookingRoles(): Promise<BookingRole[]> {
    return this.request('/bookings/roles', HTTPMethod.get, null, { id: 'get-roles' })
  }

  createBookingRole(newBookingRole: BookingRoleBody): Promise<EmptyResponse> {
    return this.request('/bookings/roles', HTTPMethod.post, newBookingRole, getRolesDeleteCacheConfig)
  }

  updateBookingRole(roleId: string, newBookingRole: BookingRoleBody): Promise<EmptyResponse> {
    return this.request(`/bookings/roles/${roleId}`, HTTPMethod.put, newBookingRole, getRolesDeleteCacheConfig)
  }

  deleteBookingRole(bookingRoleId: string): Promise<EmptyResponse> {
    return this.request(`/bookings/roles/${bookingRoleId}`, HTTPMethod.delete, null, getRolesDeleteCacheConfig)
  }

  addRoleInGroup(groupId: string, roleId: string): Promise<EmptyResponse> {
    return this.request(
      `/bookings/groups/${groupId}/roles`,
      HTTPMethod.post,
      { roleId: roleId },
      getGroupsDeleteCacheConfig
    )
  }

  deleteRoleFromGroup(groupId: string, roleId: string): Promise<EmptyResponse> {
    return this.request(
      `/bookings/groups/${groupId}/roles/${roleId}`,
      HTTPMethod.delete,
      null,
      getGroupsDeleteCacheConfig
    )
  }

  getBookingFormFieldsTypes(): Promise<string[]> {
    return this.request('/bookings/form/fields/types', HTTPMethod.get)
  }

  addFormFieldInGroup(groupId: string, formField: BookingFormFieldBody): Promise<EmptyResponse> {
    return this.request(`/bookings/groups/${groupId}/fields`, HTTPMethod.post, formField, getGroupsDeleteCacheConfig)
  }

  updateFormFieldInGroup(
    groupId: string,
    formFieldId: string,
    formField: BookingFormFieldBody
  ): Promise<EmptyResponse> {
    return this.request(
      `/bookings/groups/${groupId}/fields/${formFieldId}`,
      HTTPMethod.put,
      formField,
      getGroupsDeleteCacheConfig
    )
  }

  deleteFormFieldFromGroup(groupId: string, formFieldId: string): Promise<EmptyResponse> {
    return this.request(
      `/bookings/groups/${groupId}/fields/${formFieldId}`,
      HTTPMethod.delete,
      null,
      getGroupsDeleteCacheConfig
    )
  }

  getBookingGroups(): Promise<BookingAudienceGroup[]> {
    return this.request('/bookings/groups', HTTPMethod.get, null, {
      id: 'get-groups',
    })
  }

  createBookingAudienceGroup(newBookingAudienceGroup: BookingAudienceGroupBody): Promise<BookingAudienceGroup> {
    return this.request('/bookings/groups', HTTPMethod.post, newBookingAudienceGroup, getGroupsDeleteCacheConfig)
  }

  updateBookingAudienceGroup(
    groupId: string,
    newBookingAudienceGroup: BookingAudienceGroupBody
  ): Promise<EmptyResponse> {
    return this.request(
      `/bookings/groups/${groupId}`,
      HTTPMethod.put,
      newBookingAudienceGroup,
      getGroupsDeleteCacheConfig
    )
  }

  deleteBookingAudienceGroup(audienceGroupId: string): Promise<EmptyResponse> {
    return this.request(`/bookings/groups/${audienceGroupId}`, HTTPMethod.delete, null, getGroupsDeleteCacheConfig)
  }

  addAudienceInGroup(groupId: string, audienceId: string): Promise<EmptyResponse> {
    return this.request(
      `/bookings/groups/${groupId}/audiences`,
      HTTPMethod.post,
      { audienceId: audienceId },
      getGroupsDeleteCacheConfig
    )
  }

  deleteAudienceFromGroup(groupId: string, audienceId: string): Promise<EmptyResponse> {
    return this.request(
      `/bookings/groups/${groupId}/audiences/${audienceId}`,
      HTTPMethod.delete,
      null,
      getGroupsDeleteCacheConfig
    )
  }

  getCurrentUser(): Promise<UserProfile> {
    return this.request('/user/profile', HTTPMethod.get, null, { id: 'get-profile' })
  }
}
